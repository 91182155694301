<template>
    <fragment>
        <van-field :value="inputValue" clickable readonly v-bind="$attrs" @click-input.stop="show = true"/>
        <van-number-keyboard v-model="inputValue"
                             :show="show"
                             close-button-text="完成"
                             extra-key="."
                             theme="custom"
                             @blur="show = false"
                             @delete="onDelete"
                             @input="onInput"
        />
    </fragment>
</template>

<script>
    import {Fragment} from 'vue-fragment';

    export default {
        name: "MyNumber",
        components: {Fragment},
        props: {
            value: [String, Number]
        },
        model: {
            prop: 'value',
            event: 'input'
        },
        data() {
            return {
                show: false,
                inputValue: ''
            };
        },
        methods: {
            onInput() {
                console.log('onInput', this.inputValue);
            },
            onDelete() {
                console.log('onDelete', this.inputValue);
            }
        }
    }
</script>

<style scoped>

</style>
